<template>
<!--     <AdminWebmessageEdit :wsmkey="translation.wsmkey" :translated="translation.wsmvalue" v-if="adminModeActive"></AdminWebmessageEdit>-->
<wsm v-html="!adminModeActive ? translation.wsmvalue : ''"></wsm>
</template>

<script lang="ts">

import { useAdminStore } from '@/stores/admin/adminStore';
import {storeToRefs} from 'pinia'
import { useWebmessageStore } from '@/stores/webmessages/webmessageStore';

export default defineNuxtComponent({
    name: 'webmessage',
    props: {
        wsm: {
            type: String,
            required: true,
        }
    },
    async setup(props) {
        const adminStore = useAdminStore();
        const {adminModeActive} = storeToRefs(adminStore);
        const webmessageStore = useWebmessageStore();

        const message = await webmessageStore.wsmRetriever(props.wsm)

        let translation = {wsmkey: '', wsmvalue: ''}

        if(message) {
            translation = {wsmkey: props.wsm, wsmvalue: message[props.wsm]}
        }

        return {adminModeActive, translation, }
    },
})
</script>